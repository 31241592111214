<template>
  <div>
    <PageHeader />
    <b-overlay :show="isUpdatingDepositSettings">
      <b-card class="mb-4">
        <ValidationObserver
          ref="depositSettings"
          v-slot="{ handleSubmit }"
        >
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-form-group
              label="ยอดฝากต่ำสุด / ครั้ง (ต่อคน)"
              label-for="min_deposit"
            >
              <b-input-group>
                <b-form-input
                  id="min_deposit"
                  v-model="depositForm.min_deposit"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            
            <div class="text-right">
              <b-overlay
                :show="isUpdatingDepositSettings"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  {{ $t('buttons.edit')}}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
// import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'Deposit Settings',
  },
  data() {
    return {
      depositForm: {
        min_deposit: 0,
      },
    }
  },
  computed: {
      isUpdatingDepositSettings(){
          return false
      }
  },
  watch: {},
  created() {
    // this.fetchWithdrawSettings()
  },
  methods: {
    onSubmit() {
      //   this.updateWithdrawSettings(this.withdrawForm)
    },
  },
}
</script>
